import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWeb3Context } from 'hooks/useWeb3Context';
import { observer } from 'mobx-react';
import { ellipseAddress } from "../helpers/utilities";

import bgSrc from "./../assets/images/ground/background.png";
import foreSrc from "./../assets/images/ground/foreground.png";
import borderSrc from "./../assets/images/ground/border.png";
import groundSrc from "./../assets/images/ground/1_ground.png";
import walletOnSrc from "./../assets/images/wallet_btn/wallet_on_btn.png";
import walletOffSrc from "./../assets/images/wallet_btn/wallet_off_btn.png";
import buyToken from "./../assets/images/wallet_btn/buy_btn.png";

import gameOneEnSrc from "./../assets/images/bird_img/2_bird.png";
import gameTwoEnSrc from "./../assets/images/bird_img/2_bird_b.png";
import gameThreeEnSrc from "./../assets/images/bird_img/2_bird_r.png";
import gameDisSrc from "./../assets/images/bird_img/disable_bird.png";

import _100kSrc from "./../assets/images/bird_img/100k.png";
import _200kSrc from "./../assets/images/bird_img/200k.png";
import _400kSrc from "./../assets/images/bird_img/400k.png";

import notice1Src from "./../assets/images/panel/panel1.png";
import notice2Src from "./../assets/images/panel/panel2.png";
import notice3Src from "./../assets/images/panel/panel3.png";

const Avatar = observer(() => {
    const [notice1Flag, setNotice1Flag] = useState(false);
    const [notice2Flag, setNotice2Flag] = useState(false);
    const [notice3Flag, setNotice3Flag] = useState(false);

    const web3Context = useWeb3Context()
    const connectedAccount = web3Context.account;
    const tokenBalance = web3Context.tokenBalance;
    // const tokenBalance = 2*(10**14);

    const navigate = useNavigate();

    const canvasRef = useRef(null);
    const bgRef = useRef(null);
    const foreRef = useRef(null);
    const borderRef = useRef(null);
    const groundRef = useRef(null);
    const walletOnRef = useRef(null);
    const walletOffRef = useRef(null);

    const gameOneEnRef = useRef(null);
    const gameTwoEnRef = useRef(null);
    const gameThreeEnRef = useRef(null);
    const gameDisRef = useRef(null);

    const buyButtonRef = useRef(null);
    const text1Ref = useRef(null);
    const text2Ref = useRef(null);
    const text3Ref = useRef(null);

    const notice1Ref = useRef(null);
    const notice2Ref = useRef(null);
    const notice3Ref = useRef(null);

    useEffect(() => {
        // SELECT CVS
        const canvas = canvasRef.current;
        const canvasTx = canvas.getContext("2d");

        const bgSprite = bgRef.current;
        const foreSprite = foreRef.current;
        const borderSprite = borderRef.current;
        const groundSprite = groundRef.current;
        const walletOffSprite = walletOffRef.current;

        const gameOneEnSprite = gameOneEnRef.current;
        const gameTwoEnSprite = gameTwoEnRef.current;
        const gameThreeEnSprite = gameThreeEnRef.current;
        const gameDisSprite = gameDisRef.current;
        const buyTokenSprite = buyButtonRef.current;
        
        const text1Sprite = text1Ref.current;
        const text2Sprite = text2Ref.current;
        const text3Sprite = text3Ref.current;

        const notice1Sprite = notice1Ref.current;
        const notice2Sprite = notice2Ref.current;
        const notice3Sprite = notice3Ref.current;

        // ─── DRAW THE BACKGROUND ────────────────────────────────────────────────────────
        const background = {
            backgroundImg: bgSprite,
            position: { x: 0, y: 0 },
            drewBackground() {
                canvasTx.drawImage(
                    this.backgroundImg,
                    this.position.x,
                    this.position.y
                );
            },
        };
        // ─── DRAW THE BORDER ────────────────────────────────────────────────────────────
        const border = {
            borderImg: borderSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewBorder() {
                canvasTx.drawImage(
                    this.borderImg,
                    this.position.x,
                    this.position.y
                );
            },
        };
        // ─── DRAW THE FOREGROUND ────────────────────────────────────────────────────────
        const foreground = {
            foregroundImg: foreSprite,
            position: { x: 0, y: 0 },
            drewBackground() {
                canvasTx.drawImage(
                    this.foregroundImg,
                    this.position.x,
                    parseFloat(canvas.height - this.foregroundImg.height - 50)
                );
            },
        };
        // ─── DRAW THE GROUND ────────────────────────────────────────────────────────────
        const ground = {
            groundImg: groundSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewGround() {
                canvasTx.drawImage(
                    this.groundImg,
                    this.position.x,
                    parseFloat(canvas.height - this.groundImg.height)
                );
            },
        };
        // ─── DRAW THE WALLET ────────────────────────────────────────────────────────────        
        const walletoff = {
            walletImg: walletOffSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewWallet() {
                canvasTx.drawImage(
                    this.walletImg,
                    parseFloat(canvas.width - this.walletImg.width - 5),
                    10,
                );
                canvasTx.fillStyle = "#ffffff";
                canvasTx.font = "18px Teko";
                canvasTx.fillText(
                    `${ellipseAddress(connectedAccount)}`,
                    canvas.width - 122,
                    30
                );
            },
        };
        // AVATAR
        const gameOneEn = {
            gameImg: gameOneEnSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewAvatar() {
                canvasTx.drawImage(
                    this.gameImg,
                    this.position.x,
                    this.position.y,
                );
            },
        };
        const gameTwoEn = {
            gameImg: gameTwoEnSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewAvatar() {
                canvasTx.drawImage(
                    this.gameImg,
                    this.position.x,
                    this.position.y,
                );
            },
        };
        const gameThreeEn = {
            gameImg: gameThreeEnSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewAvatar() {
                canvasTx.drawImage(
                    this.gameImg,
                    this.position.x,
                    this.position.y,
                );
            },
        };
        const gameDis = {
            gameImg: gameDisSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewAvatar() {
                canvasTx.drawImage(
                    this.gameImg,
                    this.position.x,
                    this.position.y,
                );
            },
        };
        // ─── DRAW THE Text ────────────────────────────────────────────────────────────
        const text1 = {
            TextImg: text1Sprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewText() {
                canvasTx.drawImage(
                    this.TextImg,
                    20,
                    100
                );
            },
        };
        const text2 = {
            TextImg: text2Sprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewText() {
                canvasTx.drawImage(
                    this.TextImg,
                    20,
                    180
                );
            },
        };
        const text3 = {
            TextImg: text3Sprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewText() {
                canvasTx.drawImage(
                    this.TextImg,
                    20,
                    260
                );
            },
        };
        // ─── DRAW THE NOTICE ────────────────────────────────────────────────────────────
        const notice1 = {
            noticeImg: notice1Sprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewNotice() {
                canvasTx.drawImage(
                    this.noticeImg,
                    parseFloat((canvas.width - this.noticeImg.width) / 2),
                    parseFloat((canvas.height - this.noticeImg.height) / 2)
                );
            },
        };
        const notice2 = {
            noticeImg: notice2Sprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewNotice() {
                canvasTx.drawImage(
                    this.noticeImg,
                    parseFloat((canvas.width - this.noticeImg.width) / 2),
                    parseFloat((canvas.height - this.noticeImg.height) / 2)
                );
            },
        };
        const notice3 = {
            noticeImg: notice3Sprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewNotice() {
                canvasTx.drawImage(
                    this.noticeImg,
                    parseFloat((canvas.width - this.noticeImg.width) / 2),
                    parseFloat((canvas.height - this.noticeImg.height) / 2)
                );
            },
        };

        const buyTokenButton = {
            buyTokenImg: buyTokenSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewImage() {
                canvasTx.drawImage(
                    this.buyTokenImg,
                    60,
                    320
                );
            },
        };

        function table_view () {
            canvasTx.fillStyle = "#50255e";
            canvasTx.font = "15px Teko";

            canvasTx.fillText(
                `View`,
                canvas.width / 20,
                30
            );
        }
        
        // ────────────────────────────────────────────────────────────────────────────────
        const drewElements = () => {
            canvas.width = 250;
            canvas.height = 400;
            canvasTx.fillStyle = "#47f0a9";

            background.drewBackground();
            foreground.drewBackground();
            ground.drewGround();
            border.drewBorder();

            text1.drewText();
            text2.drewText();
            text3.drewText();
            buyTokenButton.drewImage();

            if(tokenBalance < 100000000000000) {
                gameDis.position.x = 180;
                gameDis.position.y = 80;
                gameDis.drewAvatar();

                gameDis.position.x = 180;
                gameDis.position.y = 160;
                gameDis.drewAvatar();

                gameDis.position.x = 180;
                gameDis.position.y = 240;
                gameDis.drewAvatar();
            }
            if(tokenBalance >= 100000000000000 && tokenBalance < 200000000000000) {
                gameOneEn.position.x = 180;
                gameOneEn.position.y = 80;
                gameOneEn.drewAvatar();

                gameDis.position.x = 180;
                gameDis.position.y = 160;
                gameDis.drewAvatar();

                gameDis.position.x = 180;
                gameDis.position.y = 240;
                gameDis.drewAvatar();
            }
            else if (tokenBalance >= 200000000000000 && tokenBalance < 400000000000000)
            {
                gameOneEn.position.x = 180;
                gameOneEn.position.y = 80;
                gameOneEn.drewAvatar();

                gameTwoEn.position.x = 180;
                gameTwoEn.position.y = 160;
                gameTwoEn.drewAvatar();

                gameDis.position.x = 180;
                gameDis.position.y = 240;
                gameDis.drewAvatar();
            }
            else if (tokenBalance >= 400000000000000)
            {
                gameOneEn.position.x = 180;
                gameOneEn.position.y = 80;
                gameOneEn.drewAvatar();

                gameTwoEn.position.x = 180;
                gameTwoEn.position.y = 160;
                gameTwoEn.drewAvatar();

                gameThreeEn.position.x = 180;
                gameThreeEn.position.y = 240;
                gameThreeEn.drewAvatar();
            }

            walletoff.drewWallet();

            if(notice1Flag == true)
                notice1.drewNotice();
            if(notice2Flag == true)
                notice2.drewNotice();
            if(notice3Flag == true)
                notice3.drewNotice();

            // if (connectedAccount.toLowerCase() == process.env.React_APP_ADMIN_WALLET.toLowerCase()) {
                table_view();
            // }
        };
        (function gameLoop() {
            // ? INCRESING FRAMES (fps)
            drewElements();
            requestAnimationFrame(gameLoop);
        })();

        // mouse onclick event
        canvas.addEventListener("click", function(evt){
            let rect = canvas.getBoundingClientRect();
            let clickX = evt.clientX - rect.left;
            let clickY = evt.clientY - rect.top;

            if (clickX >= 20 && clickX <= 65 && clickY >= 30 && clickY <= 50) {
                navigate('/table');
            }
            if (clickX >= 200 && clickX <= 390 && clickY >= 15 && clickY <= 60){
                web3Context.disconnect();
                navigate('/');
                window.location.reload();
            }
            // select game avatar
            if (clickX >= 280 && clickX <= 355 && clickY >= 130 && clickY <= 205)
            {
                if (tokenBalance >= 100000000000000) {
                    localStorage.setItem("avatarIndex", 1);
                    navigate('/game');
                }
                else {
                    console.log('notice1');
                    setNotice1Flag(true);
                    setNotice2Flag(false);
                    setNotice3Flag(false);
                }
            }
            else if (clickX >= 280 && clickX <= 355 && clickY >= 255 && clickY <= 335)
            {
                if (tokenBalance >= 200000000000000) {
                    localStorage.setItem("avatarIndex", 2);
                    navigate('/game');
                }
                else {
                    console.log('notice2');
                    setNotice1Flag(false);
                    setNotice2Flag(true);
                    setNotice3Flag(false);
                }
            }
            else if (clickX >= 280 && clickX <= 355 && clickY >= 388 && clickY <= 465)
            {
                if (tokenBalance >= 400000000000000) {
                    localStorage.setItem("avatarIndex", 3);
                    navigate('/game');
                }
                else {
                    console.log('notice3');
                    setNotice1Flag(false);
                    setNotice2Flag(false);
                    setNotice3Flag(true);
                }
            }
            else if (clickX >= 97 && clickX <= 285 && clickY >= 518 && clickY <= 560)
            {
                window.open('https://mm.finance/swap?outputCurrency=0x5eb71485f0736d368ddc5f290ac217d2a877fcf9');
            }
            else {
                setNotice1Flag(false);
                setNotice2Flag(false);
                setNotice3Flag(false);
            }
        });
        
        if(!connectedAccount)
            navigate('/');

    }, [connectedAccount, navigate, web3Context, tokenBalance, notice1Flag, notice2Flag, notice3Flag]);
    
    return (
        <div>
            <canvas id='canvas' ref={canvasRef}></canvas>
            <img ref={bgRef} src={bgSrc} alt="bg" hidden />
            <img ref={foreRef} src={foreSrc} alt="fore" hidden />
            <img ref={borderRef} src={borderSrc} alt="border" hidden />
            <img ref={groundRef} src={groundSrc} alt="ground" hidden />
            <img ref={walletOnRef} src={walletOnSrc} alt="walleton" hidden />
            <img ref={walletOffRef} src={walletOffSrc} alt="walletoff" hidden />
            <img ref={gameOneEnRef} src={gameOneEnSrc} alt="gameoneen" hidden />
            <img ref={gameTwoEnRef} src={gameTwoEnSrc} alt="gametwoen" hidden />
            <img ref={gameThreeEnRef} src={gameThreeEnSrc} alt="gamethreeen" hidden />
            <img ref={gameDisRef} src={gameDisSrc} alt="gamethreedis" hidden />
            <img ref={buyButtonRef} src={buyToken} alt="buyToken" hidden />


            <img ref={text1Ref} src={_100kSrc} alt="_100k" hidden />
            <img ref={text2Ref} src={_200kSrc} alt="_200k" hidden />
            <img ref={text3Ref} src={_400kSrc} alt="_400k" hidden />

            <img ref={notice1Ref} src={notice1Src} alt="notice1" hidden />
            <img ref={notice2Ref} src={notice2Src} alt="notice2" hidden />
            <img ref={notice3Ref} src={notice3Src} alt="notice3" hidden />
        </div>
    );

});

export default Avatar;
import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import bgSrc from "./../assets/images/ground/background.png";
import borderSrc from "./../assets/images/ground/border.png";
import groundSrc from "./../assets/images/ground/mount.png";
import walletOnSrc from "./../assets/images/wallet_btn/wallet_on_btn.png";
import walletOffSrc from "./../assets/images/wallet_btn/wallet_off_btn.png";
import playGuideButton from "./../assets/images/wallet_btn/how_to_play.png";
import playButton from "./../assets/images/wallet_btn/play.png";
import logoSrc from "./../assets/images/ground/logo.png";
import gameOneEnSrc from "./../assets/images/ground/icon.png";
import { useWeb3Context } from 'hooks/useWeb3Context';

function Start() {
    const web3Context = useWeb3Context()
    const connectedAccount = web3Context.account;

    const navigate = useNavigate();

    const canvasRef = useRef(null);
    const bgRef = useRef(null);
    const borderRef = useRef(null);
    const groundRef = useRef(null);
    const walletOnRef = useRef(null);
    const playRef = useRef(null);
    const playGuideRef = useRef(null);
    const walletOffRef = useRef(null);
    const logoRef = useRef(null);
    const gameOneEnRef = useRef(null);

    useEffect(() => {
        // SELECT CVS
        const canvas = canvasRef.current;
        const canvasTx = canvas.getContext("2d");

        const bgSprite = bgRef.current;
        const borderSprite = borderRef.current;
        const groundSprite = groundRef.current;
        const walletOnSprite = walletOnRef.current;
        const playOnSprite = playRef.current;
        const playGuideSprite = playGuideRef.current;
        const logoSprite = logoRef.current;
        const gameOneEnSprite = gameOneEnRef.current;

        // ─── DRAW THE BACKGROUND ────────────────────────────────────────────────────────
        const background = {
            backgroundImg: bgSprite,
            position: { x: 0, y: 0 },
            drewBackground() {
                canvasTx.drawImage(
                    this.backgroundImg,
                    this.position.x,
                    this.position.y
                );
            },
        };
        // ─── DRAW THE BORDER ────────────────────────────────────────────────────────────
        const border = {
            borderImg: borderSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewBorder() {
                canvasTx.drawImage(
                    this.borderImg,
                    this.position.x,
                    this.position.y
                );
            },
        };
        // ─── DRAW THE GROUND ────────────────────────────────────────────────────────────
        const ground = {
            groundImg: groundSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewGround() {
                canvasTx.drawImage(
                    this.groundImg,
                    this.position.x,
                    parseFloat(canvas.height - this.groundImg.height)
                );
            },
        };
        // AVATAR
        const logo = {
            logoImg: logoSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewLogo() {
                canvasTx.drawImage(
                    this.logoImg,
                    parseFloat((canvas.width - this.logoImg.width) / 2),
                    parseFloat((canvas.height - this.logoImg.height) / 5),
                );
            },
        };
        // AVATAR
        const gameOneEn = {
            gameImg: gameOneEnSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewAvatar() {
                canvasTx.drawImage(
                    this.gameImg,
                    parseFloat((canvas.width - this.gameImg.width) / 2),
                    parseFloat((canvas.height - this.gameImg.height) / 2 + 40),
                );
            },
        };
        // ─── DRAW THE WALLET ────────────────────────────────────────────────────────────
        const walleton = {
            walletImg: walletOnSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewWallet() {
                canvasTx.drawImage(
                    this.walletImg,
                    parseFloat((canvas.width - this.walletImg.width) / 2),
                    parseFloat((canvas.height - this.walletImg.height) / 2 + 130),
                );
            },
        };

        const playon = {
            playImg: playOnSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewPlay() {
                canvasTx.drawImage(
                    this.playImg,
                    parseFloat((canvas.width - this.playImg.width) / 2),
                    parseFloat((canvas.height - this.playImg.height) / 2 + 130),
                );
            },
        };

        const playguide = {
            guideImg: playGuideSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewGuide() {
                canvasTx.drawImage(
                    this.guideImg,
                    parseFloat((canvas.width - this.guideImg.width) / 2),
                    parseFloat((canvas.height - this.guideImg.height) / 2 + 160),
                );
            },
        };

        // ────────────────────────────────────────────────────────────────────────────────
        const drewElements = () => {
            canvas.width = 250;
            canvas.height = 400;
            background.drewBackground();
            ground.drewGround();
	        border.drewBorder();
            if(connectedAccount){
                playon.drewPlay();
                playguide.drewGuide();
            } else {
                walleton.drewWallet();
            }
            logo.drewLogo();
            gameOneEn.drewAvatar();
        };
        (function gameLoop() {
            // ? INCRESING FRAMES (fps)
            drewElements();
            requestAnimationFrame(gameLoop);
        })();
        
        // mouse onclick event
        canvas.addEventListener("click", function(evt){
            let rect = canvas.getBoundingClientRect();
            let clickX = evt.clientX - rect.left;
            let clickY = evt.clientY - rect.top;

            console.log(clickX, clickY);
            if(clickX >= 105 && clickX <= 295 && clickY >= 510 && clickY <= 555 && !connectedAccount){
                web3Context?.connectWallet();
            } else if(clickX >= 105 && clickX <= 295 && clickY >= 510 && clickY <= 555 && connectedAccount) {
                navigate('/avatar');
            } else if(clickX >= 140 && clickX <= 255 && clickY >= 570 && clickY <= 600 && connectedAccount) {
                window.location.replace('https://www.crowitches.com/crowitches-whitepaper.pdf');
            }
        });

    }, [connectedAccount, navigate, web3Context]);

    return (
        <div>
            <canvas id='canvas' ref={canvasRef}></canvas>
            <img ref={bgRef} src={bgSrc} alt="bg" hidden />
            <img ref={borderRef} src={borderSrc} alt="border" hidden />
            <img ref={groundRef} src={groundSrc} alt="ground" hidden />
            <img ref={playRef} src={playButton} alt="play" hidden />
            <img ref={playGuideRef} src={playGuideButton} alt="playguide" hidden />
            <img ref={walletOnRef} src={walletOnSrc} alt="walleton" hidden />
            <img ref={walletOffRef} src={walletOffSrc} alt="walletoff" hidden />
            <img ref={logoRef} src={logoSrc} alt="logo" hidden />
            <img ref={gameOneEnRef} src={gameOneEnSrc} alt="gameoneen" hidden />
            <img ref={walletOffRef} src={walletOffSrc} alt="walletoff" hidden />
        </div>
    );
}

export default Start;
import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import user from './user'
import nft from './nft'
import lottery from './lottery'

const store = configureStore({
  reducer: {
    user,
    nft,
    lottery,
  },
})

export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch()

export default store
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { getAllUser } from './../utils/Api';

const Table = observer(() => {
    const[users, setUsers] = useState([]);

    useEffect(() => {
        getAllUser().then((data) => {
            setUsers(data['data']);
        });
    }, []);

    const columns = [
        {
            dataField: "address",
            text: "",
            filter: textFilter()
        },
        {
            dataField: "best_score",
            text: "Best Score",
            sort: true
        },
        {
            dataField: "rank",
            text: "Rank",
            sort: true
        }
    ];
    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        bgColor: '#00BFFF'
    };

    return (
        <div>
            <div className='row'>
                <div className='col-md-2'>
                    <a className='btn btn-danger' href='/'>Back</a>
                </div>
            </div>
            <ToolkitProvider keyField="id" data={users} columns={columns}>
                {(props) => (
                    <div>
                        <hr />
                        <BootstrapTable
                            { ...props.baseProp }
                            bootstrap4
                            keyField="id"
                            data={users}
                            columns={columns}
                            selectRow={selectRow}
                            pagination={paginationFactory({ sizePerPage: 10 })}
                            filter={ filterFactory() }
                        />
                    </div>
                )}
            </ToolkitProvider>
        </div>
    );
})

export default Table;
import React from 'react'
import { Provider } from 'react-redux'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { Web3ContextProvider } from 'contexts/Web3Context'
import store from 'state'

const Providers: React.FC = ({ children }) => {
  return (
    <Provider store={store}>
      <Web3ContextProvider>
        <RefreshContextProvider>
          {children}
        </RefreshContextProvider>
      </Web3ContextProvider>
    </Provider>
  )
}

export default Providers

import axios from 'axios';
const baseUrl = 'https://backend.crowitches.com/api';

export const updateUserScore = (address, scores) => {
  axios
    .post(`${baseUrl}/user/score`, {
      address: address,
      scores: scores,
    })
    .catch((err) => console.error(err));
}

export const getUserRank = (address) => {
  return axios.get(`${baseUrl}/user/user_rank/${address}`).catch((err) => console.error(err));
}

export const getAllUser = () => {
  return axios.get(`${baseUrl}/user/all_user/`).catch((err) => console.error(err));
}



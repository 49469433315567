import { createAction } from '@reduxjs/toolkit'
import { TokenData } from '.'

export const updateFetching = createAction<{ fetching: boolean }>('nft/updateFetching')
export const updateMintPrice = createAction<{ mintPrice: string }>('nft/updateMintPrice')
export const updateMintPrice2 = createAction<{ mintPrice: string }>('nft/updateMintPrice2')
export const updateMintPrice3 = createAction<{ mintPrice: string }>('nft/updateMintPrice3')
export const updateTotalSupply = createAction<{ totalSupply: number }>('nft/updateTotalSupply')
export const updateTotalSupply2 = createAction<{ totalSupply: number }>('nft/updateTotalSupply2')
export const updateTotalSupply3 = createAction<{ totalSupply: number }>('nft/updateTotalSupply3')
export const updateBoostPrice = createAction<{ boostPrice: string }>('nft/updateBoostPrice')
export const updateData = createAction<{ data: TokenData[] }>('nft/updateData')
export const updateAddNFTData = createAction<{ data: TokenData }>('nft/updateAddNFTData')
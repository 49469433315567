/* eslint-disable default-case */
import React, { useRef, useEffect } from 'react';
import { ellipseAddress } from "../helpers/utilities";

import birdOneSrc from "./../assets/images/bird_img/1_bird.png";
import birdTwoSrc from "./../assets/images/bird_img/2_bird.png";
import birdThreeSrc from "./../assets/images/bird_img/3_bird.png";

import birdOneBlueSrc from "./../assets/images/bird_img/1_bird_b.png";
import birdTwoBlueSrc from "./../assets/images/bird_img/2_bird_b.png";
import birdThreeBlueSrc from "./../assets/images/bird_img/3_bird_b.png";

import birdOneRedSrc from "./../assets/images/bird_img/1_bird_r.png";
import birdTwoRedSrc from "./../assets/images/bird_img/2_bird_r.png";
import birdThreeRedSrc from "./../assets/images/bird_img/3_bird_r.png";

import walletOnSrc from "./../assets/images/wallet_btn/wallet_on_btn.png";
import walletOffSrc from "./../assets/images/wallet_btn/wallet_off_btn.png";

import bgSrc from "./../assets/images/ground/background.png";
import bg1Src from "./../assets/images/ground/background1.png";
import bg2Src from "./../assets/images/ground/background2.png";

import foreSrc from "./../assets/images/ground/foreground.png";
import fore1Src from "./../assets/images/ground/foreground1.png";
import fore2Src from "./../assets/images/ground/foreground2.png";

import groundSrc from "./../assets/images/ground/1_ground.png";
import ground1Src from "./../assets/images/ground/2_ground.png";
import ground2Src from "./../assets/images/ground/3_ground.png";

import borderSrc from "./../assets/images/ground/border.png";

import upPipeSrc from "./../assets/images/pipe_img/toppipe1.png";
import upPipe1Src from "./../assets/images/pipe_img/toppipe.png";
import upPipe2Src from "./../assets/images/pipe_img/toppipe2.png";

import boPipeSrc from "./../assets/images/pipe_img/botpipe1.png";
import boPipe1Src from "./../assets/images/pipe_img/botpipe.png";
import boPipe2Src from "./../assets/images/pipe_img/botpipe2.png";

import getReadySrc from "./../assets/images/start&over_game_img/getready.png";
import gameOverSrc from "./../assets/images/start&over_game_img/gameOver.png";
import tap1Src from "./../assets/images/start&over_game_img/1_tap.png";
import tap2Src from "./../assets/images/start&over_game_img/2_tap.png";

import goldSrc from "./../assets/images/start&over_game_img/gold.png"
import silverSrc from "./../assets/images/start&over_game_img/silver.png"
import copperSrc from "./../assets/images/start&over_game_img/copper.png"
import noneSrc from "./../assets/images/start&over_game_img/medal_none.png"

import { useWeb3Context } from 'hooks/useWeb3Context';

import { updateUserScore, getUserRank } from './../utils/Api';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

const Game = observer(() => {

    const web3Context = useWeb3Context();
    const connectedAccount = web3Context.account;

    const canvasRef = useRef(null);

    const birdOneRef = useRef(null);
    const birdTwoRef = useRef(null);
    const birdThreeRef = useRef(null);

    const birdOneBlueRef = useRef(null);
    const birdTwoBlueRef = useRef(null);
    const birdThreeBlueRef = useRef(null);

    const birdOneRedRef = useRef(null);
    const birdTwoRedRef = useRef(null);
    const birdThreeRedRef = useRef(null);

    const bgRef = useRef(null);
    const bg1Ref = useRef(null);
    const bg2Ref = useRef(null);

    const foreRef = useRef(null);
    const fore1Ref = useRef(null);
    const fore2Ref = useRef(null);
    const borderRef = useRef(null);

    const groundRef = useRef(null);
    const ground1Ref = useRef(null);
    const ground2Ref = useRef(null);

    const upPipeRef = useRef(null);
    const upPipe1Ref = useRef(null);
    const upPipe2Ref = useRef(null);

    const boPipeRef = useRef(null);
    const boPipe1Ref = useRef(null);
    const boPipe2Ref = useRef(null);

    const getReadyRef = useRef(null);
    const gameOverRef = useRef(null);

    const tap1Ref = useRef(null);
    const tap2Ref = useRef(null);

    const walletOnRef = useRef(null);
    const walletOffRef = useRef(null);

    const goldRef = useRef(null);
    const silverRef = useRef(null);
    const copperRef = useRef(null);
    const noneRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        const canvas = canvasRef.current;
        const canvasTx = canvas.getContext("2d");
        
        const birdOnesprite = birdOneRef.current;
        const birdTwosprite = birdTwoRef.current;
        const birdThreesprite = birdThreeRef.current;

        const birdOneBluesprite = birdOneBlueRef.current;
        const birdTwoBluesprite = birdTwoBlueRef.current;
        const birdThreeBluesprite = birdThreeBlueRef.current;

        const birdOneRedsprite = birdOneRedRef.current;
        const birdTwoRedsprite = birdTwoRedRef.current;
        const birdThreeRedsprite = birdThreeRedRef.current;

        const bgSprite = bgRef.current;
        const bg1Sprite = bg1Ref.current;
        const bg2Sprite = bg2Ref.current;

        const foreSprite = foreRef.current;
        const fore1Sprite = fore1Ref.current;
        const fore2Sprite = fore2Ref.current;

        const groundSprite = groundRef.current;
        const ground1Sprite = ground1Ref.current;
        const ground2Sprite = ground2Ref.current;

        const borderSprite = borderRef.current;

        const upPipeSprite = upPipeRef.current;
        const upPipe1Sprite = upPipe1Ref.current;
        const upPipe2Sprite = upPipe2Ref.current;

        const boPipeSprite = boPipeRef.current;
        const boPipe1Sprite = boPipe1Ref.current;
        const boPipe2Sprite = boPipe2Ref.current;

        const getReadySprite = getReadyRef.current;
        const gameOverSprite = gameOverRef.current;

        const tap1Sprite = tap1Ref.current;
        const tap2Sprite = tap2Ref.current;

        const walletOffSprite = walletOffRef.current;

        const goldSprite = goldRef.current;
        const silverSprite = silverRef.current;
        const copperSprite = copperRef.current;
        const noneSprite = noneRef.current;
        
        var screenFrame = 0;
        const RAD = Math.PI / 180;

        var player = {
            gameActive: true,
            gamePlaying: false,
        };

        var flag = true;
        
        // ─── DRAW AND ANIMETED THE BRID ──────────────────────────────────────────────────────────
        const playingObject = {
            frames: 0,
            position: { x: 60, y: 50 },
            speed: 0,
            gravity: 0.2,
            thrust: 2.6,
            rotatation: 0,
            imgAnimation: [
                { playObjectImg: birdOnesprite },
                { playObjectImg: birdTwosprite },
                { playObjectImg: birdThreesprite },
            ],
            selectBird() {
                let avatar = localStorage.getItem('avatarIndex');

                if(avatar == 2)
                {
                    this.imgAnimation = [
                        { playObjectImg: birdOneBluesprite },
                        { playObjectImg: birdTwoBluesprite },
                        { playObjectImg: birdThreeBluesprite },
                    ];
                    this.gravity = 0.16;
                }
                if(avatar == 3)
                {
                    this.imgAnimation = [
                        { playObjectImg: birdOneRedsprite },
                        { playObjectImg: birdTwoRedsprite },
                        { playObjectImg: birdThreeRedsprite },
                    ];
                    this.gravity = 0.125;
                }
            },
            drewPlayObject() {
                canvasTx.save();
                canvasTx.translate(this.position.x, this.position.y);
                canvasTx.rotate(this.rotatation * RAD);
                canvasTx.drawImage(
                    this.imgAnimation[this.frames].playObjectImg,
                    -this.imgAnimation[this.frames].playObjectImg.width / 2,
                    -this.imgAnimation[this.frames].playObjectImg.height / 2
                );
                canvasTx.restore();
            },
            updateFrames() {                
                if (player.gameActive) {
                    this.frames += screenFrame % 10 == 0 ? 1 : 0; // * CONTROL FPS
                    this.position.y += screenFrame % 8 == 0 ? Math.sin(screenFrame * RAD) : 0; // * TRANSLATE THE BRID
                } else if (player.gamePlaying) {
                    this.frames += screenFrame % 10 == 0 ? 1 : 0; // * CONTROL FPS
                    this.position.y += this.speed;
                    this.speed += this.gravity;
                    this.objectRotation();                    
                    this.collisioned();
                } else if (!player.gameActive && !player.gamePlaying) {
                    this.frames = 1;
                    if (
                        this.position.y +
                            parseFloat(this.imgAnimation[0].playObjectImg.width / 2) <
                        ground.position.y
                    ) {
                        this.position.y += this.speed;
                        this.objectRotation();
                        this.speed += this.gravity * 2;
                    }
                }
                this.frames = this.frames % this.imgAnimation.length; // ? CONTROL BRID ANIMETION
            },
            flying() {
                // ? WHEN KEY PRESS THEN RUN
                if (this.position.y > 0) {
                    this.speed = -this.thrust;
                }
            },
            objectRotation() {
                if (this.speed <= 0) {
                    this.rotatation = Math.max(-25, (-25 * this.speed) / (-1 * this.thrust));
                } else if (this.speed > 0) {
                    this.rotatation = Math.min(90, (90 * this.speed) / (this.thrust * 2));
                }
            },
            collisioned() {
                if (!pipe.pipe.length) return;

                let x = pipe.pipe[0].x;
                let y = pipe.pipe[0].y;

                let bridPro =
                    parseFloat(this.imgAnimation[0].playObjectImg.width) / 4 +
                    parseFloat(this.imgAnimation[0].playObjectImg.width) / 4;

                let groudPro = parseFloat(canvas.height - ground.groundImg.height);
                let pipeH = y + parseFloat(pipe.topPipe.pipeImg.height);
                let pipeW = parseFloat(pipe.topPipe.pipeImg.width);
                            
                if (groudPro - bridPro <= this.position.y) {
                    player.gamePlaying = false;
                }
                if (this.position.x + bridPro / 2 >= x) {
                    if (this.position.x + bridPro < x + pipeW) {
                        if (
                            this.position.y - bridPro <= pipeH ||
                            this.position.y + bridPro >= pipeH + pipe.pipeGap
                        ) {
                            player.gamePlaying = false;
                        }
                    }
                }
            },
        };
        // ─── DRAW THE BACKGROUND ────────────────────────────────────────────────────────
        const background = {
            backgroundImg: bgSprite,
            position: { x: 0, y: 0 },
            selectBg() {
                let avatar = localStorage.getItem('avatarIndex');

                if(avatar == 2)
                {
                    this.backgroundImg = bg1Sprite;
                }
                if(avatar == 3)
                {
                    this.backgroundImg = bg2Sprite;
                }
            },
            drewBackground() {
                canvasTx.drawImage(
                    this.backgroundImg,
                    this.position.x,
                    this.position.y,
                );
            },
        };
        // ─── DRAW THE FOREGROUND ────────────────────────────────────────────────────────
        const foreground = {
            foregroundImg: foreSprite,
            position: { x: 0, y: 0 },
            selectForeground() {
                let avatar = localStorage.getItem('avatarIndex');

                if(avatar == 2)
                {
                    this.foregroundImg = fore1Sprite;
                }
                if(avatar == 3)
                {
                    this.foregroundImg = fore2Sprite;
                }
            },
            drewBackground() {
                canvasTx.drawImage(
                    this.foregroundImg,
                    this.position.x,
                    parseFloat(canvas.height - this.foregroundImg.height - 50)
                );
            },
        };
        // ─── DRAW THE BORDER ────────────────────────────────────────────────────────────
        const border = {
            borderImg: borderSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewBorder() {
                canvasTx.drawImage(
                    this.borderImg,
                    this.position.x,
                    this.position.y
                );
            },
        };
        // ─── DRAW THE GROUND ────────────────────────────────────────────────────────────
        const ground = {
            groundImg: groundSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            selectGround() {
                let avatar = localStorage.getItem('avatarIndex');

                if(avatar == 2)
                {
                    this.groundImg = ground1Sprite;
                }
                if(avatar == 3)
                {
                    this.groundImg = ground2Sprite;
                }
            },
            drewGround() {
                canvasTx.drawImage(
                    this.groundImg,
                    this.position.x,
                    parseFloat(canvas.height - this.groundImg.height)
                );
            },
            updateGround() {
                if (player.gamePlaying) {
                    this.position.x -= this.dx;
                    this.position.x = this.position.x % (this.groundImg.width / 14);
                }
            },
        };
        // ─── DRAW THE PIPES ,TOP AND BOTTOM ──────────────────────────────────────────────
        const pipe = {
            topPipe: { pipeImg: upPipeSprite },
            bottomPipe: { pipeImg: boPipeSprite },
            pipe: [],
            pipeGap: 100,
            move: true,
            selectPipe() {
                let avatar = localStorage.getItem('avatarIndex');

                if(avatar == 2)
                {
                    this.topPipe.pipeImg = upPipe1Sprite;
                    this.bottomPipe.pipeImg = boPipe1Sprite;
                    this.pipeGap = 110
                }
                if(avatar == 3)
                {
                    this.topPipe.pipeImg = upPipe2Sprite;
                    this.bottomPipe.pipeImg = boPipe2Sprite;
                    this.pipeGap = 120
                }
            },
            drewPipe() {
                for (let i = 0; i < this.pipe.length; i++) {
                    let p = this.pipe[i];
                    canvasTx.drawImage(this.topPipe.pipeImg, p.x, p.y);
                    canvasTx.drawImage(
                    this.bottomPipe.pipeImg, p.x, p.y + this.topPipe.pipeImg.height + this.pipeGap);
                }
            },
            pipeUpdate() {
                if (player.gameActive) {
                    if (screenFrame % 135 == 0) {
                        // ? AUTOPLAYING
                        let ren = -210 * Math.min(Math.random() + 0.9, 1.7);
                        this.pipe.push({
                            x: parseFloat(canvas.width),
                            y: ren,
                        });
                        playingObject.position.y = ren + this.topPipe.pipeImg.height + this.pipeGap - 60;
                    }
                    this.pipe.forEach((pipe) => {
                        pipe.x -= ground.dx;
                    });
                } else if (player.gamePlaying) {
                    if (screenFrame % 100 == 0) {
                        let ren = -210 * Math.min(Math.random() + 0.9, 1.7);
                        this.pipe.push({
                            x: parseFloat(canvas.width),
                            y: ren,
                        });
                    }
                    if (this.move) {
                        UI.score[0].current++;
                        this.move = false;

                        // ? SETING SCORE
                        UI.score[0].best = Math.max(
                            UI.score[0].current,
                            UI.score[0].best
                        );

                        // update user score to db
                        let scores = [UI.score[0].current, UI.score[0].best];
                        updateUserScore(connectedAccount, scores);
                        // get user rank from db
                        getUserRank(connectedAccount).then(({data})=> {
                            UI.score[0].best = data[0]['best_score'];
                            UI.score[0].rank = data[0]['rank'];
                        });
                    }
                    this.pipe.forEach((pipe) => {
                        pipe.x -= ground.dx;
                    });
                }
                if (this.pipe.length && this.pipe[0].x < -this.topPipe.pipeImg.width) {
                    this.pipe.shift();
                    this.move = true;
                }
            },
        };
        // ─── START AND END UI DEGIN ─────────────────────────────────────────────────────
        const UI = {
            frames: 0,
            game: [{ start: getReadySprite }, { over: gameOverSprite }],
            tap: [{ tapImg: tap1Sprite }, { tapImg: tap2Sprite }],
            score: [{ current: 0, best: 0, rank: 0}],
            drewUI() {
                if (player.gameActive) {
                    canvasTx.fillStyle = "rgb(126 255 90 / 30%)";
                    canvasTx.fillRect(0, 0, canvas.width, canvas.height);
                    canvasTx.drawImage(
                        this.game[0].start,
                        (canvas.width - this.game[0].start.width) / 2,
                        (canvas.height - this.game[0].start.height) / 3
                    );
                    canvasTx.drawImage(
                        this.tap[this.frames].tapImg,
                        (canvas.width - this.tap[this.frames].tapImg.width) / 2,
                        (canvas.height - this.tap[this.frames].tapImg.height) / 1.7
                    );
                }
                if (!player.gamePlaying && !player.gameActive) {
                    canvasTx.fillStyle = "rgb(60 100 20 / 20%)";
                    canvasTx.fillRect(0, 0, canvas.width, canvas.height);
                    canvasTx.drawImage(
                        this.game[1].over,
                        (canvas.width - this.game[1].over.width) / 2,
                        (canvas.height - this.game[1].over.height) / 2
                    );
                }
                this.drewScore();
            },
            drewScore() {
                canvasTx.fillStyle = "#2d8b26";
                canvasTx.font = "25px Teko";
                if (player.gamePlaying) {
                    canvasTx.fillText(
                        `Score : ${this.score[0].current}`,
                        canvas.width / 20,
                        canvas.height - 60
                    );
                    canvasTx.strokeText(
                        `Score : ${this.score[0].current}`,
                        canvas.width / 20,
                        canvas.height - 60
                    );
                } else if (player.gameActive) {
                    if (flag) {
                        // get user rank from db
                        getUserRank(connectedAccount).then(({data})=> {
                            if(data[0] == undefined) {
                                // update user score to db
                                let scores = [0, 0];
                                updateUserScore(connectedAccount, scores);
                            }
                            else {
                                UI.score[0].best = data[0]['best_score'];
                                UI.score[0].rank = data[0]['rank'];
                            }
                            flag = false;
                        });
                    }
                    canvasTx.fillText(
                        `Best : ${this.score[0].best}`,
                        canvas.height / 20,
                        canvas.height - 60
                    );
                    canvasTx.strokeText(
                        `Best : ${this.score[0].best}`,
                        canvas.height / 20,
                        canvas.height - 60
                    );

                    canvasTx.fillText(
                        `Rank : ${this.score[0].rank}`,
                        canvas.width - 100,
                        canvas.height - 60
                    );
                    canvasTx.strokeText(
                        `Rank : ${this.score[0].rank}`,
                        canvas.width - 100,
                        canvas.height - 60
                    );
                } else {
                    // score
                    canvasTx.fillText(
                        `${this.score[0].current}`,
                        85,
                        canvas.height / 2 - 10
                    );
                    canvasTx.strokeText(
                        `${this.score[0].current}`,
                        85,
                        canvas.height / 2 - 10
                    );
                    // rank
                    canvasTx.fillText(
                        `${this.score[0].rank}`,
                        85,
                        canvas.height / 2 + 55
                    );
                    canvasTx.strokeText(
                        `${this.score[0].rank}`,
                        85,
                        canvas.height / 2 + 55
                    );
                    // 
                    medal.selectMedal();
                    medal.drewMedal();
                }
            },
            updateUI() {                
                this.frames += screenFrame % 20 == 0 ? 1 : 0;
                this.frames = this.frames % this.tap.length;
            },
        };
        // ─── DRAW THE WALLET ────────────────────────────────────────────────────────────       
        const walletoff = {
            walletImg: walletOffSprite,
            dx: 2,
            position: { x: 0, y: 0 },
            drewWallet() {
                canvasTx.drawImage(
                    this.walletImg,
                    parseFloat(canvas.width - this.walletImg.width - 5),
                    10,
                );
                canvasTx.fillStyle = "#ffffff";
                canvasTx.font = "18px Teko";
                canvasTx.fillText(
                    `${ellipseAddress(connectedAccount)}`,
                    canvas.width - 122,
                    30
                );
            },
        };
        // ─── DRAW THE MEDAL ────────────────────────────────────────────────────────────
        const medal = {
            medalImg: noneSprite,
            position: { x: 0, y: 0 },
            drewMedal() {
                canvasTx.drawImage(
                    this.medalImg,
                    175,
                    parseFloat(canvas.height / 2 - 10)
                );
            },
            selectMedal() {
                if (UI.score[0].rank == 1) {
                    this.medalImg = goldSprite;
                }
                if (UI.score[0].rank == 2) {
                    this.medalImg = silverSprite;
                }
                if (UI.score[0].rank == 3) {
                    this.medalImg = copperSprite;
                }
            },
        };
        // ────────────────────────────────────────────────────────────────────────────────
        const updateElements = () => {
            playingObject.updateFrames();
            ground.updateGround();
            pipe.pipeUpdate();
            UI.updateUI();
        };
        const drewElements = () => {
            canvas.height = 400;
            canvas.width = 250;
            canvasTx.fillStyle = "#47f0a9";
            canvasTx.fillRect(0, 0, canvas.width, canvas.height);

            background.selectBg();
            background.drewBackground();

            foreground.selectForeground();
            foreground.drewBackground();

            pipe.selectPipe();
            pipe.drewPipe();

            ground.selectGround();
            ground.drewGround();

            border.drewBorder();

            playingObject.selectBird();
            playingObject.drewPlayObject();

            walletoff.drewWallet();
            UI.drewScore();
            UI.drewUI();
        };
        (function gameLoop() {
            // ? INCRESING FRAMES (fps)
            screenFrame++;
            updateElements();
            drewElements();
            requestAnimationFrame(gameLoop);
        })();
        // CONTROL THE GAME
        canvas.addEventListener("click", function(evt){
            let rect = canvas.getBoundingClientRect();
            let clickX = evt.clientX - rect.left;
            let clickY = evt.clientY - rect.top;

            // console.log(clickX, clickY);
            if (clickX >= 200 && clickX <= 390 && clickY >= 15 && clickY <= 60){
                // disconnect wallet
                // console.log("clicked");
                web3Context.disconnect();
                navigate('/');
                window.location.reload();
            }
            else {
                if (player.gameActive) {
                    player.gameActive = false;
                    player.gamePlaying = true;
                    pipe.pipe = [];
                }
                if (player.gamePlaying) {
                    playingObject.flying();   
                } else {
                    // console.log(clickX, clickY);
                    // play again
                    if (clickX >= 35 && clickX <= 190 && clickY >= 465 && clickY <= 500){
                        pipe.pipe = [];
                        playingObject.rotatation = 0;
                        playingObject.position.y = 50;
                        UI.score[0].current = 0;
                        player.gameActive = true;
                        player.gamePlaying = false;
                    }
                    // quit
                    if (clickX >= 210 && clickX <= 365 && clickY >= 465 && clickY <= 500){
                        navigate('/avatar');
                    }
                    
                }
            }
        });

        if(!connectedAccount)
            navigate('/');
    }, [connectedAccount, navigate, web3Context]);

    return (
        <div>
            <canvas id='canvas' ref={canvasRef}></canvas>
            <img ref={birdOneRef} src={birdOneSrc} alt="bird_1" hidden/>
            <img ref={birdTwoRef} src={birdTwoSrc} alt="bird_2" hidden />
            <img ref={birdThreeRef} src={birdThreeSrc} alt="bird_3" hidden />

            <img ref={birdOneBlueRef} src={birdOneBlueSrc} alt="bird_b_1" hidden/>
            <img ref={birdTwoBlueRef} src={birdTwoBlueSrc} alt="bird_b_2" hidden />
            <img ref={birdThreeBlueRef} src={birdThreeBlueSrc} alt="bird_b_3" hidden />

            <img ref={birdOneRedRef} src={birdOneRedSrc} alt="bird_r_1" hidden/>
            <img ref={birdTwoRedRef} src={birdTwoRedSrc} alt="bird_r_2" hidden />
            <img ref={birdThreeRedRef} src={birdThreeRedSrc} alt="bird_r_3" hidden />

            <img ref={bgRef} src={bgSrc} alt="bg" hidden />
            <img ref={bg1Ref} src={bg1Src} alt="bg1" hidden />
            <img ref={bg2Ref} src={bg2Src} alt="bg2" hidden />

            <img ref={foreRef} src={foreSrc} alt="fore_bg" hidden />
            <img ref={fore1Ref} src={fore1Src} alt="fore1_bg" hidden />
            <img ref={fore2Ref} src={fore2Src} alt="fore2_bg" hidden />

            <img ref={groundRef} src={groundSrc} alt="ground" hidden />
            <img ref={ground1Ref} src={ground1Src} alt="ground1" hidden />
            <img ref={ground2Ref} src={ground2Src} alt="ground2" hidden />

            <img ref={borderRef} src={borderSrc} alt="border" hidden />

            <img ref={upPipeRef} src={upPipeSrc} alt="up_pipe" hidden />
            <img ref={upPipe1Ref} src={upPipe1Src} alt="up_pipe" hidden />
            <img ref={upPipe2Ref} src={upPipe2Src} alt="up_pipe" hidden />

            <img ref={boPipeRef} src={boPipeSrc} alt="bo_pipe" hidden />
            <img ref={boPipe1Ref} src={boPipe1Src} alt="bo_pipe" hidden />
            <img ref={boPipe2Ref} src={boPipe2Src} alt="bo_pipe" hidden />

            <img ref={getReadyRef} src={getReadySrc} alt="getready" hidden />
            <img ref={gameOverRef} src={gameOverSrc} alt="gameover" hidden />

            <img ref={tap1Ref} src={tap1Src} alt="tap1" hidden />
            <img ref={tap2Ref} src={tap2Src} alt="tap2" hidden />

            <img ref={walletOnRef} src={walletOnSrc} alt="walleton" hidden />
            <img ref={walletOffRef} src={walletOffSrc} alt="walletoff" hidden />

            <img ref={goldRef} src={goldSrc} alt="gold" hidden />
            <img ref={silverRef} src={silverSrc} alt="silver" hidden />
            <img ref={copperRef} src={copperSrc} alt="copper" hidden />
            <img ref={noneRef} src={noneSrc} alt="none" hidden />
        </div>
    );
});

export default Game;

import * as React from "react";
import * as ReactDOM from "react-dom";
import { createGlobalStyle } from "styled-components";
import App from "./App";
// import { globalStyle } from "./assets/css/style.css";
import Providers from "./Providers";
import { BrowserRouter } from 'react-router-dom';
const globalStyle = require("./assets/css/style.css");

const GlobalStyle = createGlobalStyle`
  ${globalStyle}
`;

// @ts-ignore
declare global {
  // tslint:disable-next-line
  interface Window {
    web3: any;
    ethereum: any;
    Web3Modal: any;
    [name: string]: any;
  }
}

ReactDOM.render(
  <BrowserRouter>
    <GlobalStyle />
    <Providers>
      <App />
    </Providers>
  </BrowserRouter>,
  document.getElementById("root")
);

import * as React from "react";
import { Routes, Route } from 'react-router-dom';
import Start from './pages/Start'
import Avatar from './pages/Avatar';
import Game from './pages/Game';
import Table from './pages/Table';

interface IAppState {
  address: string;
  web3: any;
  provider: any;
  connected: boolean;
  chainId: number;
  networkId: number;
}

const INITIAL_STATE: IAppState = {
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: 25,
  networkId: 25,
};

class App extends React.Component<any, any> {
  // @ts-ignore
  public state: IAppState;

  constructor(props: any) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  public render = () => {
    return (
      <main>
        {/* <div className="gameDetail">
          <h1 className="title">Flappy Bird</h1>
        </div> */}
        <Routes>
          <Route path='/' element={<Start />} />
          <Route path='/avatar' element={<Avatar />} />
          <Route path='/game' element={<Game />} />
          <Route path='/table' element={<Table />} />
        </Routes>
      </main>
    );
  };
}

export default App;

import { createReducer } from '@reduxjs/toolkit'

import { updateFetching, updateJoinData, updateUserData } from './actions'

export interface JoinInfo {
  roundNumber: string
  roundPrice: string
  userAmount: string
}

export interface UserInfo {
  firstWinner: string
  secondWinner: string
}

export interface LotteryState {
  fetching: boolean
  joinData: JoinInfo[]
  userData: UserInfo[]
}

export const initialState: LotteryState = {
  fetching: false,
  joinData: [
    {
      roundNumber: '0',
      roundPrice: '10000000000000',
      userAmount: '6',
    },
    {
      roundNumber: '0',
      roundPrice: '10000000000000',
      userAmount: '6',
    },
    {
      roundNumber: '0',
      roundPrice: '10000000000000',
      userAmount: '6',
    },
  ],
  userData: [
    {
      firstWinner: '0',
      secondWinner: '0',
    },
    {
      firstWinner: '0',
      secondWinner: '0',
    },
    {
      firstWinner: '0',
      secondWinner: '0',
    },
  ],
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(updateFetching, (state, { payload: { fetching } }) => {
      state.fetching = fetching
    })
    .addCase(updateJoinData, (state, { payload: { joinData } }) => {
      state.joinData[joinData.type].roundNumber = joinData.roundNumber
      state.joinData[joinData.type].roundPrice = joinData.roundPrice
      state.joinData[joinData.type].userAmount = joinData.userAmount
    })
    .addCase(updateUserData, (state, { payload: { userData } }) => {
      state.userData[userData.type].firstWinner = userData.firstWinner
      state.userData[userData.type].secondWinner = userData.secondWinner
    })
})

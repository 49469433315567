import React, { useEffect, useState, ReactElement } from "react";
import { StaticJsonRpcProvider, JsonRpcProvider } from "@ethersproject/providers";
import { RPC_URL, INFURA_ID, CHAIN } from 'config'
import { getChainData } from 'utils/getChainData'
import { Contract, ethers, providers } from 'ethers'
import Web3Modal from 'web3modal'
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3 from "web3";
import ABI from '../config/ABI.json'

export type Web3ContextData = {
  provider: JsonRpcProvider;
  setProvider: any;
  account: string;
  setAccount: any;
  connectWallet: any;
  switchNetwork: any;
  disconnect: any;
  chainId: number;
  tokenBalance: number;
} | null;

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber
      }
    ]
  });

  return web3;
}

export const Web3Context = React.createContext<Web3ContextData>(null);

export const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [provider, setProvider] = useState<JsonRpcProvider>(new StaticJsonRpcProvider(RPC_URL));
  const [account, setAccount] = useState<string>('');
  const [snackbar, setSnackbar] = useState<any>(null);
  const [chainId, setChainId] = useState<number>(0);
  const [tokenBalance, setTokenBalance] = useState<number>(0);

  const providerOptions =  {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
            [25]: RPC_URL,
        },
      }
    }
  }

  // const [web3Modal, setWeb3Modal] = useState()
  const web3Modal = new Web3Modal({
    network: getChainData(CHAIN).network,
    cacheProvider: true, // optional
    providerOptions: providerOptions
  })
  


  const subscribeProvider = (provider: any) => {
    provider.on("disconnect", (error: any) => {
      console.log("Wallet disconnected.");
      setAccount('');
    });
    provider.on("accountsChanged", (accounts: any) => {
      console.log("account changed.")
      setAccount(accounts[0]?.toLowerCase());
      setSnackbar({
        type: "info",
        message: "Account Changed",
      });
    });
    // Subscribe to chainId change
    provider.on("chainChanged", (chainId: number) => {
      console.log("Chain Id: ", chainId)
      setChainId(chainId);
      connectWallet()
    });
  };

  useEffect(() => {
    const eagerConnect = async () => {
      await connectWallet()
    }
    if (web3Modal.cachedProvider) {
      eagerConnect()
    }
  }, []);



  const connectWallet = async () => {
    try{
      const provider = await web3Modal.connect()
      await subscribeProvider(provider);
      await provider.enable();
      const web3: any = initWeb3(provider);
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      const chainId = await web3.eth.chainId();
      const library = new ethers.providers.Web3Provider(provider)
      // const accounts = await library.listAccounts()
      const tokenAddress = "0x5Eb71485f0736d368ddC5f290ac217d2A877fCf9";
      const newProvider = new providers.JsonRpcProvider('https://evm-cronos.crypto.org/');
      const contract = new Contract(tokenAddress, ABI, newProvider);
      (async () => {
        const result = await contract.balanceOf(address?.toLowerCase());
        setTokenBalance(result);
        })();
      
      if (accounts) {
        setAccount(address?.toLowerCase())
      }
      if (library) {
        setProvider(provider)
      }
      if(provider) {
        // setChainId(chainId)
        setChainId(Number(provider.chainId))
      }
      if (chainId != 25) {
        switchNetwork();
      }

    } catch (error) {
      console.error(error);
    }
  }

  const switchNetwork = async() => {
    const provider = await web3Modal.connect()
    await subscribeProvider(provider);
    await provider.enable();
    const web3: any = initWeb3(provider);

    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(CHAIN) }],
      });
    }
    catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: web3.utils.toHex(CHAIN),
              chainName: 'CronosTestnet',
              rpcUrls: [RPC_URL] /* ... */,
            },
          ],
        });
        return {
          success: true,
          message: "switching succeed"
        }
      } catch (addError) {
        return {
          success: false,
          message: "Switching failed." + addError
        }
      }
    }
  }

  const disconnect = async () => {
    
    await web3Modal.clearCachedProvider()
    setAccount('')
  }

  return <Web3Context.Provider value={{ provider, setProvider, account, chainId, setAccount, connectWallet, switchNetwork, disconnect, tokenBalance }}>{children}</Web3Context.Provider>;
};
